import * as React from "react";
import { Image } from "mui-image";

import {
  // Avatar,
  Box,
  Button,
  // Checkbox,
  Divider,
  // FormControlLabel,
  // Grid,
  // Link,
  TextField,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";

// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { LoadingButton } from "@mui/lab";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

export default function SignIn() {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    authError,
    isAuthenticated,
    isLoading,
    resetAuthError,
    signIn: performLogin,
  } = useAuth();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const password = data.get("password");

    if (!email || !password) {
      return;
    }

    performLogin(email.toString(), password.toString())
      .then(() => {
        const from = location.state?.from?.pathname || "/";

        navigate(from, { replace: true });
      })
      .catch((error: Error) => {
        console.warn(error.message);
      });
  };

  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
    <LockOutlinedIcon />
  </Avatar> */}
      <Image src="/assets/novo-logo.png" height="auto" width="200px" />
      <Divider style={{ marginTop: "20px", width: "100%" }}>
        <Typography component="h1" variant="h5">
          LOGIN
        </Typography>
      </Divider>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Endereço de E-mail"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Senha"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        {/* <FormControlLabel
      control={<Checkbox value="remember" color="primary" />}
      label="Remember me"
    /> */}
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          loading={isLoading}
          disabled={isLoading}
        >
          Entrar
        </LoadingButton>
        {/* <Grid container>
      <Grid item xs>
        <Link href="#" variant="body2">
          Forgot password?
        </Link>
      </Grid>
      <Grid item>
        <Link href="#" variant="body2">
          {"Don't have an account? Sign Up"}
        </Link>
      </Grid>
    </Grid> */}
      </Box>
      {!!authError && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={resetAuthError}
        >
          <Alert severity="error">
            {authError?.response?.status === 401 && "Usuário/Senha inválidos"}
            {(!authError?.response || authError?.response.status === 500) &&
              "Tente novamente mais tarde"}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
}
