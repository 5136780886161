import * as React from "react";
import { Image } from "mui-image";

import {
  // Avatar,
  Box,
  Button,
  // Checkbox,
  Divider,
  // FormControlLabel,
  // Grid,
  // Link,
  TextField,
  Typography,
  Snackbar,
  Alert,
  AlertProps,
  CircularProgress,
} from "@mui/material";

// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { LoadingButton } from "@mui/lab";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

export default function ResetPassword() {
  // TODO: check on backend if resetPasswordToken exists and show error using setSnackbar for redirecting to Login
  const navigate = useNavigate();
  const { resetPasswordToken } = useParams();
  const [allowReset, setAllowReset] = React.useState<boolean | undefined>();

  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const {
    authError,
    checkResetPassword,
    isAuthenticated,
    isLoading,
    resetAuthError,
    resetPassword,
  } = useAuth();

  const errorAndRedirect = (message: string) => {
    setSnackbar({ children: message, severity: "error" });
    setTimeout(() => {
      navigate("/sign-in");
    }, 3000);
  };

  React.useEffect(() => {
    if (!resetPasswordToken) {
      errorAndRedirect("Link inválido");
      return;
    }

    checkResetPassword(resetPasswordToken)
      .then(() => {
        setAllowReset(true);
      })
      .catch((error) => {
        setAllowReset(false);
        errorAndRedirect(error.message);
      });
  }, []);

  const handleSubmit = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const newPassword = data.get("password");
      const confirmPassword = data.get("confirm-password");

      if (!resetPasswordToken) {
        setSnackbar({
          children: "Link para Recuperação de Senha é inválido",
          severity: "error",
        });
        return;
      }

      if (!newPassword) {
        setSnackbar({
          children: 'O campo "Nova Senha" é obrigatório',
          severity: "error",
        });
        return;
      }

      if (!confirmPassword) {
        setSnackbar({
          children: 'O campo "Confirmação Senha" é obrigatório',
          severity: "error",
        });
        return;
      }

      if (newPassword !== confirmPassword) {
        setSnackbar({
          children:
            'Os campos "Nova Senha" e "Confirmação Senha" devem possuir o mesmo valor',
          severity: "error",
        });
        return;
      }

      console.log({ resetPasswordToken });

      resetPassword(resetPasswordToken, newPassword.toString())
        .then(() => {
          console.log("success");
          setSnackbar({
            children: "Senha atualizada com sucesso!",
            severity: "success",
          });
        })
        .catch((error) => {
          console.warn(error.message);
        });
    },
    [resetPasswordToken]
  );

  const handleCloseSuccessSnackbar = () => {
    const isSuccess = snackbar?.severity === "success";
    setSnackbar(null);

    if (isSuccess) {
      navigate("/sign-in");
    }
  };

  // const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //   const newPassword = data.get("password");
  //   const confirmPassword = data.get("confirm-password");

  //   if (newPassword !== confirmPassword) {
  //     // TODO: raise error snackbar
  //     return;
  //   }

  //   console.log({ resetPasswordToken });
  //   // resetPassword(resetPasswordToken, newPassword);
  // };

  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Image src="/assets/novo-logo.png" height="auto" width="200px" />
      <Divider style={{ marginTop: "20px", width: "100%" }}>
        <Typography component="h1" variant="h5">
          Recuperar Senha
        </Typography>
      </Divider>
      {isLoading && <CircularProgress />}
      {!isLoading && allowReset && (
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Nova Senha"
            type="password"
            id="password"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirm-password"
            label="Confirmação Senha"
            type="password"
            id="confirm-password"
          />
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            loading={isLoading}
            disabled={isLoading}
          >
            Enviar
          </LoadingButton>
        </Box>
      )}
      <Snackbar
        open={!!snackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleCloseSuccessSnackbar}
        autoHideDuration={2500}
      >
        <Alert {...snackbar} onClose={handleCloseSuccessSnackbar} />
      </Snackbar>
      {!!authError && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={resetAuthError}
        >
          <Alert severity="error">
            {authError?.response?.status === 401 && "Usuário/Senha inválidos"}
            {(!authError?.response || authError?.response.status === 500) &&
              "Tente novamente mais tarde"}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
}
